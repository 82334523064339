import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";

import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";

import { ValidateEmail, axiosConfig } from "../../../config/utility";

import { Theme1 } from "../../../components/Themes/theme-1";
import { Theme2 } from "../../../components/Themes/theme-2";
import { Theme3 } from "../../../components/Themes/theme-3";
import { Theme4 } from "../../../components/Themes/theme-4";
import { Theme5 } from "../../../components/Themes/theme-5";
import { Theme7 } from "../../../components/Themes/theme-7";
import { Theme9 } from "../../../components/Themes/theme-9";

import Error404 from "../../../components/Errors/404";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import helpherClass from "../../../config/helperClass";
const auth = new helpherClass();

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      errorEmail: "",
      errorPass: "",
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      password: "",
      errorMessage: "",
      disabled: false,
      loader: "",
      successMessage: "",
      resellerData: [],
      tableLoader: true,
      res_accountno: "",
      res_url: "",
      res_hash: "",
      res_title: "",
      pageError: "",
      selected_theme: "1",
      theme_data: [],
      current_url: "",
      rfq_random_number:""
    };
  }

  async componentDidMount() {
    let agent_url = this.props.match.params.agent_url;
    let agent_hash = this.props.match.params.url_hash;
    let urlElements = window.location.href.split("/");
    console.log("CUrrent URL: ", urlElements[2]);
    let host_url = urlElements[2];
    console.log("URL Details: ", agent_url);
    console.log("URL Details: ", agent_hash);
    //if (agent_url !== "" && agent_hash !== "") {
    //Errors
    //404 and no_reseller
    //404 and no_agent
    let rfq_url = window.location.href;
    var wordToReplace = "home";
    var replacement = "rfq";

    // Use JavaScript's replace() function to replace the word
    var newUrl = rfq_url.replace(wordToReplace, replacement);
    //var rfq_random_number= this.generateRandomNumber();
    this.setState({
      current_url: newUrl,
    });
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/check_sharelink_detail",
          JSON.stringify({
            agent_url: agent_url,
            agent_hash: agent_hash,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            host_url: host_url,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("check_sharelink_detail response.data : ", response.data);
          if (
            response.data.status === 404 ||
            response.data.status === 422 ||
            response.data.errors !== ""
          ) {
            this.setState({
              errorMessage: "",
              successMessage: "",
              show_form: false,
              selected_theme: "",
              pageError:
                "Sorry we are unable to process your request. Please contact admin for more details.",
            });
          }

          if (
            response.data.status === 404 &&
            response.data.message === "no_reseller"
          ) {
            this.setState({
              errorMessage: "",
              pageError:
                "Reseller does not exists. Please contact support or try again with correct URL.",
              successMessage: "",
              show_form: false,
              selected_theme: "",
            });
          } else if (response.data.status === 403) {
            this.setState({
              errorMessage: "There is some error.",
              successMessage: "",
              show_form: false,
              pageError: "",
              selected_theme: "",
            });
          } else if (
            response.data.status === 200 &&
            response.data.message === "no_landing"
          ) {
            this.setState({
              errorMessage: "",
              selected_theme: "6",
              tableLoader: true,
            });
            window.location.replace(response.data.register_url);
          } else if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            console.log(
              "Selected Theme: ",
              response.data.sharelinkData.themes[0]["theme_identifier"]
            );
            console.log("URL : fasfsafsafasf");
            this.setState({
              errorMessage: "",
              selected_theme:
                response.data.sharelinkData.themes[0]["theme_identifier"],
              theme_data: response.data.sharelinkData,
              
              tableLoader: false,
            });
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        selected_theme: "",
        show_form: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }
    /*} else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        selected_theme: "",
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
    }*/
  }

  generateRandomNumber = () => {
    const min = 1000000000; // Minimum 10-digit number (10^9)
    const max = 9999999999; // Maximum 10-digit number (10^10 - 1)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  };

  formSubmit = () => {
    console.log("Parent Submit Form: ");
    //e.preventDefault();
    let { name, email, phone, subject, message } = this.state;

    console.log("name: ", name);
    console.log("email: ", email);
    console.log("phone: ", phone);
    console.log("subject: ", subject);
    console.log("message: ", message);
    /*if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Email is required.",
      });
    } else if (ValidateEmail(email) === false) {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Invalid email format.",
      });
    } else if (password === "") {
      return this.setState({
        errorPass: "error",
        errorEmail: "",
        errorMessage: "Password is required.",
      });
    } else {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        disabled: true,
        loader: "show",
      });

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "login",
            JSON.stringify({
              email: email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            }),
            axiosConfig
          )
          .then((response) => {
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessage: response.data.errors,
                disabled: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Please wait, redirecting you to the dashboard.",
                errorMessage: "",
              });
              Cookies.set(
                "tkn__",
                CryptoJS.AES.encrypt(
                  response.data.token,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "acn__",
                CryptoJS.AES.encrypt(
                  response.data.accountno,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "eml__",
                CryptoJS.AES.encrypt(
                  response.data.email,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "comp_nme__",
                CryptoJS.AES.encrypt(
                  response.data.comp_name,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "name__",
                CryptoJS.AES.encrypt(
                  response.data.name,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "phone__",
                CryptoJS.AES.encrypt(
                  response.data.phone,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "adress__",
                CryptoJS.AES.encrypt(
                  response.data.address,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "title__",
                CryptoJS.AES.encrypt(
                  response.data.title,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              setTimeout(function () {
                //this.props.history.push("/dashboard");
                //  window.location = "/dashboard";
                window.location.replace("/dashboard");
              }, 1000);
            }
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    }*/
  };

  render() {
    /* if (auth.isLogin() === true) {
      return <Redirect to="/dashboard" />;
    }*/

    console.log("this.state.selected_theme: ", this.state.selected_theme);
    console.log("this.state.current_url: ", this.state.current_url);
    const { selected_theme, pageError, theme_data } = this.state;
    let status = null;
    if (theme_data.sharelink && theme_data.sharelink.length > 0) {
      status = theme_data.sharelink[0].status;
    } else {
      console.log("Sharelink array is empty or undefined.");
    }
    console.log("Sharelink Status", status);

    if (status === 0) {
      return <Error404 />;
    }
    return (
      <>
        
        {this.state.selected_theme === "theme-1" ? (
          <div key="mytheme1">
            <Theme1
              id="th1"
              theme_data={this.state.theme_data}
              formSubmit={this.formSubmit}
              handleChange={this.handleChange}
              currentUrl={this.state.current_url}
              rfq_random_number={this.state.rfq_random_number}
            />
          </div>
        ) : null}
        {this.state.selected_theme === "theme-3" ? (
          <div key="mytheme3">
            <Theme3
              id="th3"
              theme_data={this.state.theme_data}
              formSubmit={this.formSubmit}
              handleChange={this.handleChange}
              currentUrl={this.state.current_url}
              rfq_random_number={this.state.rfq_random_number}
            />
          </div>
        ) : null}
        {this.state.selected_theme === "theme-5" ? (
          <div key="mytheme5">
            <Theme5
              id="th5"
              theme_data={this.state.theme_data}
              formSubmit={this.formSubmit}
              handleChange={this.handleChange}
              currentUrl={this.state.current_url}
              rfq_random_number={this.state.rfq_random_number}
            />
          </div>
        ) : null}
        {this.state.selected_theme === "theme-4" ? (
          <div key="mytheme4">
            <Theme4
              id="th4"
              theme_data={this.state.theme_data}
              formSubmit={this.formSubmit}
              handleChange={this.handleChange}
              currentUrl={this.state.current_url}
              rfq_random_number={this.state.rfq_random_number}
            />
          </div>
        ) : null}
        {this.state.selected_theme === "theme-2" ? (
          <div key="mytheme2">
            <Theme2
              id="th2"
              theme_data={this.state.theme_data}
              formSubmit={this.formSubmit}
              handleChange={this.handleChange}
              currentUrl={this.state.current_url}
              rfq_random_number={this.state.rfq_random_number}
            />
          </div>
        ) : null}
        {this.state.selected_theme === "theme-7" ? (
          <div key="mytheme7">
            <Theme7
              id="th7"
              theme_data={this.state.theme_data}
              formSubmit={this.formSubmit}
              handleChange={this.handleChange}
              currentUrl={this.state.current_url}
              rfq_random_number={this.state.rfq_random_number}
            />
          </div>
        ) : null}
        {this.state.selected_theme === "theme-9" ? (
          <div key="mytheme9">
            <Theme9
              id="th9"
              theme_data={this.state.theme_data}
              formSubmit={this.formSubmit}
              handleChange={this.handleChange}
              currentUrl={this.state.current_url}
              rfq_random_number={this.state.rfq_random_number}
            />
          </div>
        ) : null}
        {this.state.selected_theme === "" ? <Error404 id="th8" /> : null}
        
      </>
    );
    //return <Theme3 theme_data={this.state.theme_data} />;
    /*switch (this.state.selected_theme) {
      case "theme-1":
        return (
          <>
            <HelmetProvider>
              <Theme1 id="th1" theme_data={this.state.theme_data} />
              <Helmet>
                <script src="../../assets/js/jquery.js"></script>

                <script src="../../assets/js/plugins.js"></script>
                <script src="../../assets/js/init.js"></script>
              </Helmet>
            </HelmetProvider>
          </>
        );
        break;
      case "theme-3":
        return (
          <>
            <HelmetProvider>
              <Theme3 id="th2" theme_data={this.state.theme_data} />
              <Helmet>
                <script src="../../assets/js/jquery.js"></script>

                <script src="../../assets/js/plugins.js"></script>
                <script src="../../assets/js/init.js"></script>
              </Helmet>
            </HelmetProvider>
          </>
        );
        break;
      case "theme-5":
        return (
          <>
            <HelmetProvider>
              <Theme5 id="th3" theme_data={this.state.theme_data} />
              <Helmet>
                <script src="../../assets/js/jquery.js"></script>

                <script src="../../assets/js/plugins.js"></script>
                <script src="../../assets/js/init.js"></script>
              </Helmet>
            </HelmetProvider>
          </>
        );
        break;
      default:
        return (
          <>
            <HelmetProvider>
              <Theme5 id="th4" theme_data={this.state.theme_data} />
              <Helmet>
                <script src="../../assets/js/jquery.js"></script>

                <script src="../../assets/js/plugins.js"></script>
                <script src="../../assets/js/init.js"></script>
              </Helmet>
            </HelmetProvider>
          </>
        );
    }*/
  }
}
