import React, { Component } from "react";
//import { Helmet, HelmetProvider } from "react-helmet-async";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import $ from "jquery";
import { ValidateEmail, axiosConfig, normalize } from "../../config/utility";
import ReCAPTCHA from "react-google-recaptcha";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const newHeight = window.screen.height;
export class Theme9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      dnsList: [],
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      currentPdfLink: "",
      currentlink: "",
      themeData: this.props.theme_data,
      showCaptcha: true,
    };
    this.site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    this.captcha_token = null;
  }

  get_props = () => {
    return this.props.theme_data;
  };

  pre_loader() {
    $(function () {
      setTimeout(function () {
        $("#consua-preloader").addClass("loaded");
        $("#loading").fadeOut(500);

        if ($("#consua-preloader").hasClass("loaded")) {
          $("#preloader")
            .delay(900)
            .queue(function () {
              $(this).remove();
            });
        } else {
          $("#consua-preloader").addClass("loaded");
          $("#loading").fadeOut(500);
        }
      }, 2000);
    });
  }

  componentDidMount() {
    let theme_data = this.get_props();
    var propVal = theme_data;
    this.pre_loader();
    $(document).on("click", "#send_message", (e) => {
      e.preventDefault();

      let name = $("#name").val();
      let email = $("#email").val();
      let phone = $("#phone").val();
      let subject = $("#subject").val();
      let message = $("#message").val();

      let is_validated = true;
      if (name === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Name is required</div>'
        );
        is_validated = false;
      } else if (email === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Email is required</div>'
        );
        is_validated = false;
      } else if (ValidateEmail(email) === false) {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Invalid email format.</div>'
        );

        is_validated = false;
      } else if (phone === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Phone is required</div>'
        );
        is_validated = false;
      } else if (subject === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Subject is required</div>'
        );
        is_validated = false;
      } else if (message === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Message is required</div>'
        );
        is_validated = false;
      }

      if (!is_validated) {
        return;
      }

      if (!this.captcha_token) {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Invalid captcha, please retry.</div>'
        );
        return;
      } else {
        this.setState({
          showCaptcha: false,
        });
      }

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "agents/submit_inquiry",
            JSON.stringify({
              name: name,
              email: email,
              subject: subject,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              phone: phone,
              message: message,
              reseller_name: propVal.reseller_detail[0].reseller_comp,
              agent_email: propVal.sharelink_settings[0].contact_email,
              agent_name: propVal.agent_data[0]["name"],
              sharelink_id: propVal.sharelink_settings[0].sharelink_id,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("Contact Response.data : ", response.data);
            if (response.data.status === 404) {
              $("#aler_success").html("");
              $("#aler_error").html(
                '<div class="alert alert-danger" role="alert">There is some error while submitting your inquiry.</div>'
              );
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              $("#aler_error").html("");
              $("#aler_success").html(
                '<div class="alert alert-success" role="alert">Your inquiry has been sent successfully. We will contact you shortly.</div>'
              );
              $("#name").val("");
              $("#phone").val("");
              $("#subject").val("");
              $("#message").val("");
              $("#email").val("");
              this.captcha_token = null;
              this.setState({
                showCaptcha: true,
              });
            }
          });
      } catch (error) {
        $("#aler_success").html("");
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">There is some error while submitting your inquiry.</div>'
        );
        console.log("catch : ", error);
      }
      setTimeout(() => {
        $("#aler_error").html("");
        $("#aler_success").html("");
      }, 5000);
    });

    $(document).on("click", ".download_rate_deck", function () {
      console.log("clicked", propVal);
      var element = $(this);
      var parent = element.closest(".download_rate_deck");
      var page_id = parent.find(".downloadratedeck").val();
      console.log("PAGE ID: ", page_id);
      if (page_id !== "") {
        try {
          axios
            .post(
              process.env.REACT_APP_API_URL + "agents/get_rate_deck_fileurl",
              JSON.stringify({
                token: process.env.REACT_APP_API_TOKEN,
                api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
                page_id: page_id,
                reseller_accountno: propVal.agent_data[0]["reseller_accountno"],
              }),
              axiosConfig
            )
            .then((reportsData) => {
              console.log("Contact Response.data : ", reportsData.data);
              if (reportsData.data.status === 404) {
                $("#aler_success").html("");
                $("#aler_error").html(
                  '<div class="alert alert-danger" role="alert">There is some error while submitting your inquiry.</div>'
                );
              }

              if (
                reportsData.data.status === 200 &&
                reportsData.data.message === "success"
              ) {
                var fileName = "";
                var url = reportsData.data.data;
                var a = document.createElement("a");
                a.href = url;
                fileName = url.split("/").pop();
                //alert(fileName);
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
              }
            });
        } catch (error) {
          console.log("catch : ", error);
        }
      } else {
        console.log("Page ID is missing");
      }
      //window.location.href = click_route;
    });
  }

  handlePhoneNumber = (value) => {
    return value.replace(phoneRegex, "($1) $2-$3");
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  getSrviceTitle = (service_id) => {
    let theme_data = this.get_props();
    var propVal = theme_data.all_services;
    console.log("getSrviceTitle propVal: ", propVal);

    for (var i = 0, len = propVal.length; i < len; i++) {
      if (propVal[i].id === service_id) {
        let service_type = "";
        if (propVal[i].service_type === "did") {
          service_type = "DID Origination";
        } else if (propVal[i].service_type === "tfn") {
          service_type = "Toll Free Origination";
        } else if (propVal[i].service_type === "term") {
          service_type = "Voice Termination";
        } else if (propVal[i].service_type === "teams") {
          service_type = "Microsoft Teams UCAAS";
        } else if (propVal[i].service_type === "tfn_term") {
          service_type = "Toll Free Termination";
        } else if (propVal[i].service_type === "az_term") {
          service_type = "International A-Z Termination";
        } else if (propVal[i].service_type === "live_agent") {
          service_type = "Live Agent";
        } else if (propVal[i].service_type === "cnam") {
          service_type = "CNAM";
        } else if (propVal[i].service_type === "sms") {
          service_type = "SMS";
        }

        return (
          <>
            <td>{propVal[i].service_title}</td>
            <td>{service_type}</td>
          </>
        );
      }
    }
    return null;
  };

  getColSize = () => {
    const length = this.props.theme_data.sharelink_services.length;
    if (length === 1) {
      return "12";
    }
    return length % 2 === 0 ? "6" : "4";
  };

  submitForm = async () => {
    // e.preventDefault();
    /*const { name, email, phone, subject, message } = this.state;
    console.log({
      name: name,
      email: email,
      subject: subject,
      token: process.env.REACT_APP_API_TOKEN,
      api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
      phone: phone,
      message: message,
      reseller_name: this.props.theme_data.reseller_detail[0].reseller_comp,
      agent_email: this.props.theme_data.sharelink_settings[0].contact_email,
      agent_name: this.props.theme_data.agent_data[0]["name"],
    });
    if (this.fieldValidation() === true) {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "agents/submit_inquiry",
            JSON.stringify({
              name: name,
              email: email,
              subject: subject,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              phone: phone,
              message: message,
              reseller_name:
                this.props.theme_data.reseller_detail[0].reseller_comp,
              agent_email:
                this.props.theme_data.sharelink_settings[0].contact_email,
              agent_name: this.props.theme_data.agent_data[0]["name"],
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("response.data : ", response.data);
            if (response.data.status === 404) {
              this.setState({
                errorMessage:
                  "There is some error while submitting your inquiry.",
                disabled: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Your inquiry has been sent successfully. We will contact you shortly.",
                errorMessage: "",
                name: "",
                phone: "",
                subject: "",
                message: "",
                email: "",
                fieldsHide: true,
              });
            }
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    }
    setTimeout(() => {
      this.setState({ errorMessage: "", successMessage: "" });
    }, 5000);*/
  };

  fieldValidation = () => {
    let { name, email, phone, subject, message } = this.state;

    if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
        errorcPass: "",
      });
      return false;
    } else if (subject === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorTitle: "error",
        errorMessage: "Subject is required.",
        errorcPass: "",
      });
      return false;
    } else if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorTitle: "",
        errorMessage: "Email is required.",
        errorcPass: "",
      });
      return false;
    } else if (message === "") {
      this.setState({
        errorMessage: "error",
        errorEmail: "",
        errorMessage: "Message is required.",
        errorTitle: "",
        errorcPass: "",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
        errorcPass: "",
      });
      return false;
    } else {
      this.setState({
        errorcPass: "",
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        disabled: true,
      });
    }
    return true;
  };
  scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  openLink = (link) => {
    this.setState({
      currentlink: link,
    });
    window.$("#modalMarketingLink").modal("show");
  };

  openPDFlink = (link) => {
    this.setState({
      currentPdfLink: link,
    });
    window.$("#modalMarketingPDFLink").modal("show");
  };

  modalHideMarketingLink = () => {
    this.setState({
      currentlink: "",
    });
    window.$("#modalMarketingLink").modal("hide");
  };

  modalHideMarketingPDF = () => {
    this.setState({
      currentPdfLink: "",
    });
    window.$("#modalMarketingPDFLink").modal("hide");
  };

  handleCaptchaChange = (value) => {
    this.captcha_token = value;
  };

  scrollToContactForm = () => {
    const contactForm = document.getElementById("contact");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    const { theme_data, key } = this.props;
    let default_other_settings = JSON.parse(
      this.props.theme_data.sharelink_default_data[0].data_json
    );
    let other_settings;
    try {
      const responseOtherSettings =
        this.props.theme_data.sharelink_settings[0].other_settings;
      other_settings = JSON.parse(
        responseOtherSettings || default_other_settings
      );
    } catch (error) {
      console.warn(
        "Failed to parse other_settings, using default settings:",
        error
      );
      other_settings = default_other_settings;
    }
    console.log("other_settings:", other_settings);
    return (
      <>
        <HelmetProvider>
          <Helmet>
            <meta
              name="description"
              content={this.props.theme_data.sharelink[0]["meta_description"]}
            />
            <title>{this.props.theme_data.sharelink[0]["meta_title"]}</title>
          </Helmet>
        </HelmetProvider>

        {/* === START PRELOADER === */}
        <div id="preloader">
          <div id="consua-preloader" className="consua-preloader">
            <div className="animation-preloader">
              <div className="spinner"></div>
              <div className="txt-loading">
                {this.props.theme_data.agent_data[0].comp_name
                  .split("")
                  .map((letter, index) => (
                    <span
                      key={index}
                      data-text-preloader={letter}
                      className="letters-loading"
                    >
                      {letter}
                    </span>
                  ))}
              </div>
            </div>
            <div className="loader">
              <div className="row">
                <div className="col-3 loader-section section-left">
                  <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-left">
                  <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                  <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                  <div className="bg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="obs-bg-header">
          <header>
            <nav className="navbar mobile-sidenav navbar-theme-secondary navbar-common navbar-sticky navbar-default validnavs on no-full sticked navbar-customStyle-theme-obs">
              <div className="top-search">
                <div className="container-xl">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fa fa-search"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <span className="input-group-addon close-search">
                      <i className="fa fa-times"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="container nav-box d-flex justify-content-between align-items-center flex-direction-reverse-sm-obs">
                <div className="navbar-header navbar-header-customStyle-obs">
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#navbar-menu"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <a className="navbar-brand navbar-brand-customStyle" href="#">
                    {this.props.theme_data.logos ? (
                      <img
                        src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.logos[0]["picture"]}`}
                        alt={this.props.theme_data.logos[0]["image_title"]}
                        className="logo"
                      />
                    ) : (
                      <img src="../../../assets/img/logo/dark.png" alt="" />
                    )}
                  </a>
                </div>
                <div className="main-nav-content">
                  <div className="collapse navbar-collapse" id="navbar-menu">
                    {this.props.theme_data.logos ? (
                      <img
                        src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.logos[0]["picture"]}`}
                        alt={this.props.theme_data.logos[0]["image_title"]}
                        className="logo"
                      />
                    ) : (
                      <img src="../../../assets/img/logo/dark.png" alt="" />
                    )}
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navbar-menu"
                    >
                      <i className="fa fa-times fa-bars"></i>
                    </button>
                    <ul
                      className="nav navbar-nav navbar-right obs-nav-link"
                      data-in="fadeInDown"
                      data-out="fadeOutUp"
                    >
                    {other_settings.navbar.nav_link1 && other_settings.navbar.nav_link1 !== "" ? (
                      <li>
                        <a
                          className="text-uppercase obs-nav-link-color"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToSection("home");
                          }}
                        >
                          {other_settings.navbar.nav_link1}
                        </a>
                      </li>
                      ) : null}
                      {other_settings.navbar.nav_link2 && other_settings.navbar.nav_link2 !== "" ? (
                        <li>
                        <a
                          className="text-uppercase obs-nav-link-color"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToSection("service");
                          }}
                        >
                          {other_settings.navbar.nav_link2}
                        </a>
                      </li>
                      ) : null}
                      {other_settings.navbar.nav_link3 && other_settings.navbar.nav_link3 !== "" ? (
                        <li>
                        <a
                          className="text-uppercase obs-nav-link-color"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToSection("about");
                          }}
                        >
                          {other_settings.navbar.nav_link3}
                        </a>
                      </li>
                      ) : null}
                      {other_settings.navbar.nav_link4 && other_settings.navbar.nav_link4 !== "" ? (
                        <li>
                        <a
                          className="text-uppercase obs-nav-link-color"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.scrollToSection("process");
                          }}
                        >
                          {other_settings.navbar.nav_link4}
                        </a>
                      </li>
                      ) : null}
                      {this.props.theme_data.sharelink_settings[0][
                        "contact_email"
                      ] && other_settings.navbar.nav_link5 && other_settings.navbar.nav_link5 !== "" ? (
                        <li>
                          <a
                            className="text-uppercase obs-nav-link-color"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.scrollToSection("contact");
                            }}
                          >
                            {other_settings.navbar.nav_link5}
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="overlay-screen"></div>
                </div>
                <div>
                  <div className="attr-right attr-right-customStyle">
                    <div className="attr-nav attr-box">
                      <ul>
                        <li className="side-menu">
                          <a href="#">
                            <span className="bar-1"></span>
                            <span className="bar-2"></span>
                            <span className="bar-3"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="side customInsurance-side side-customStyle-obs">
                    <a href="#" className="close-side">
                      <i className="icon_close"></i>
                    </a>
                    <div className="widget address ps-4">
                      {other_settings.sidebar.contact_section_title &&
                      other_settings.sidebar.contact_section_title !== "" ? (
                        <h5 className="sidebar-widget-title">
                          {other_settings.sidebar.contact_section_title}
                        </h5>
                      ) : null}
                      <div>
                        <ul>
                          {this.props.theme_data.sharelink_settings[0][
                            "contact_address"
                          ] &&
                          this.props.theme_data.sharelink_settings[0][
                            "contact_address"
                          ] !== "0" ? (
                            <li>
                              <div class="content">
                                <i class="fas fa-map-marker-alt sidebar-widget-icons"></i>
                                <strong className="sidebar-widget-desc">
                                  {
                                    this.props.theme_data.sharelink_settings[0][
                                      "contact_address"
                                    ]
                                  }
                                </strong>
                              </div>
                            </li>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0][
                            "contact_email"
                          ] ? (
                            <li>
                              <div class="content">
                                <i class="fa fa-envelope sidebar-widget-icons"></i>
                                <strong className="sidebar-widget-desc">
                                  {
                                    this.props.theme_data.sharelink_settings[0][
                                      "contact_email"
                                    ]
                                  }
                                </strong>
                              </div>
                            </li>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0][
                            "contact_phone"
                          ] &&
                          this.props.theme_data.sharelink_settings[0][
                            "contact_phone"
                          ] !== "0" ? (
                            <li>
                              <div class="content">
                                <i
                                  class="fa fa-phone sidebar-widget-icons"
                                  style={{ transform: "rotate(90deg)" }}
                                ></i>
                                <strong className="sidebar-widget-desc">
                                  {this.handlePhoneNumber(
                                    this.formatPhoneNumber(this.props.theme_data.sharelink_settings[0][
                                      "contact_phone"
                                    ])
                                  )}
                                </strong>
                              </div>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                    <div className="widget ps-4">
                    {other_settings.sidebar.social_section_title &&
                      other_settings.sidebar.social_section_title !== "" ? (
                        <h5 className="sidebar-widget-title">
                          {other_settings.sidebar.social_section_title}
                        </h5>
                      ) : null}
                      {(this.props.theme_data.sharelink_settings[0]
                        .facebook_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .facebook_link !== "https://www.facebook.com/") ||
                      (this.props.theme_data.sharelink_settings[0]
                        .twitter_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .twitter_link !== "https://twitter.com/") ||
                      (this.props.theme_data.sharelink_settings[0]
                        .instagram_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .instagram_link !== "https://www.instagram.com/") ||
                      (this.props.theme_data.sharelink_settings[0]
                        .linkedin_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .linkedin_link !== "https://www.linkedin.com/") ||
                      (this.props.theme_data.sharelink_settings[0]
                        .youtube_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .youtube_link !== "https://www.youtube.com/") ? (
                        <>
                          {this.props.theme_data.sharelink_settings[0]
                            .facebook_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .facebook_link !== "https://www.facebook.com/" ? (
                            <div className="obs-sidebar-widget-socials">
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .facebook_link
                                }
                                target="_blank"
                              >
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            </div>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0]
                            .twitter_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .twitter_link !== "https://twitter.com/" ? (
                            <div className="obs-sidebar-widget-socials">
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .twitter_link
                                }
                                target="_blank"
                              >
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0]
                            .instagram_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .instagram_link !== "https://www.instagram.com/" ? (
                            <div className="obs-sidebar-widget-socials">
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .instagram_link
                                }
                                target="_blank"
                              >
                                <i className="fab fa-instagram"></i>
                              </a>
                            </div>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0]
                            .linkedin_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .linkedin_link !== "https://www.linkedin.com/" ? (
                            <div className="obs-sidebar-widget-socials">
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .linkedin_link
                                }
                                target="_blank"
                              >
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0]
                            .youtube_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .youtube_link !== "https://www.youtube.com/" ? (
                            <div className="obs-sidebar-widget-socials">
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .youtube_link
                                }
                                target="_blank"
                              >
                                <i className="fab fa-youtube"></i>
                              </a>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlay-screen"></div>
            </nav>
          </header>
          <div
            id="home"
            className="banner-area content-right navigation-icon-solid navigation-right-botom navigation-custom-large overflow-hidden bg-cover obs-banner-area"
            style={{
              width: "94%",
              margin: "auto",
              borderRadius: "2rem",
              background: this.props.theme_data.banners
                ? `url(${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.banners[0]["picture"]})`
                : "url(../../../assets/theme/img/obs-theme/banner.png)",
            }}
          >
            <div className="banner-style-two-carousel">
              <div className="swiper-wrapper">
                <div className="swiper-slide banner-style-two-customStyle-obs">
                  <div className="container">
                    <div className="row align-center">
                      <div className="col-xl-7 col-lg-9">
                        <div className="content obs-max-width-575">
                          <div className="new-banner-text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.props.theme_data.sharelink_settings[0]
                                    .about_me_short,
                              }}
                              className="obs-banner-html"
                            />
                          </div>
                          <div className="button_customStyle button_customStyle-obs">
                            <a
                              className="btn circle btn-theme secondary btn-md animation custom_btn-Quotation3 radius background-ff8b73 customStyle-banner-btns-obs text-uppercase"
                              href={`${process.env.REACT_APP_AGENT_PORTAL_URL}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}`}
                              target="_blank"
                            >
                              {other_settings.banner.btn_text1 &&
                              other_settings.banner.btn_text1 !== ""
                                ? other_settings.banner.btn_text1
                                : "REGISTER NOW"}
                            </a>
                            <a
                              className="btn circle btn-theme secondary btn-md animation custom_btn-Quotation3 radius background-577ffa customStyle-banner-btns-obs ms-3 text-uppercase"
                              href={this.props.currentUrl}
                              target="_blank"
                            >
                              {other_settings.banner.btn_text2 &&
                              other_settings.banner.btn_text2 !== ""
                                ? other_settings.banner.btn_text2
                                : "REQUEST FOR QUOTATION"}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="obs-wrapper-about_process_contact">
          <div
            id="service"
            className="services-style-two-area default-padding bottom-less custom-bottom-less bg-cover ptop-md-90"
          >
            <div class="container ">
              <div class="row services_handle_margin">
                <div class="col-lg-12 ">
                  <div class="site-heading theme-3_site-heading_customStyle text-center">
                    <h4 class="text-color-65589d fs-3 fw-500">
                      {other_settings.services.title &&
                      other_settings.services.title !== ""
                        ? other_settings.services.title
                        : ""}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="container ">
              <div className="row services_handle_margin_blocks customRow-theme4">
                {this.props.theme_data.sharelink_services.map(
                  (services, index) => (
                    <div
                      key={`serv${services.id}`}
                      className={`col-lg-6 col-xl-6 col-md-6 mb-30`}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="services-style-two services-style-two-customstyle obs-services-customstyle"
                        data-bs-toggle="modal"
                        data-bs-target={`#serviceModal${services.id}`}
                      >
                        <div
                          className="info rounded"
                          style={{ maxWidth: "600px", margin: "auto" }}
                        >
                          <div className="button" style={{ maxWidth: "90%" }}>
                            <h4 className="fw-500 fs-24px text-color-65589d obs-service-title">
                              {services.service_title}
                            </h4>
                            <h5 className="services_max_height_obs obs-service-summary">
                              {services.product_summary &&
                              services.product_summary !== ""
                                ? services.product_summary
                                : ""}
                            </h5>
                          </div>
                          {other_settings.services.service_read_more_btn &&
                          other_settings.services.service_read_more_btn !==
                            "" ? (
                            <a
                              href="#"
                              className="button mt-0 obs-service-read_more"
                            >
                              {other_settings.services.service_read_more_btn}
                              <i className="fa fa-arrow-right ms-3"></i>
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div
            id="about"
            className="about-style-one-area default-padding default-padding_customStyle"
          >
            <div className="about-style-three-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="about-style-three about-style-three_customStyle-theme3 mt-3 mb-5">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            this.props.theme_data.sharelink_settings[0]
                              .about_me,
                        }}
                      />
                      <div className="button mt-5">
                        <a
                          className="btn circle btn-theme secondary btn-md animation custom_btn-Quotation3 radius background-ff8b73 obs-rq4qt-btn"
                          href={this.props.currentUrl}
                          target="_blank"
                        >
                          {other_settings.about.btn_text &&
                          other_settings.about.btn_text !== ""
                            ? other_settings.about.btn_text
                            : "Request For Quotation"}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex flex-column gap-3 mb-5">
                    <div className="container mt-3">
                      <div className="row">
                        <img
                        src={
                              this.props.theme_data.avatars &&
                              this.props.theme_data.avatars[0]
                                ? `${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.avatars[0]["picture"]}`
                                : "../../../assets/theme/img/obs-theme/marketing-home-img-22-2.png"
                            }
                          alt="Handshake"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {other_settings.process.step1_title !== "" ? (
            <>
              <div
                id="process"
                className="services-style-two-area bottom-less bg-cover obs-customStyle-process"
              >
                <div className="container obs-process-steps-lg">
                  {other_settings.process.title &&
                  other_settings.process.title !== "" ? (
                    <>
                      <h2 className="text-center my-5 text-color-65589d fw-500 fs-3">
                        {other_settings.process.title}
                      </h2>
                    </>
                  ) : null}
                  <div className="row justify-content-center align-items-center">
                    {/* Left Side */}
                    <div className="col-md-4">
                      <div className="d-flex flex-column align-items-end">
                        {other_settings.process.step1_title &&
                        other_settings.process.step1_title !== "" ? (
                          <>
                            <div className="step mb-4 text-end d-flex flex-row-reverse">
                              <div className="process-step-circle process-step-circle-left">
                                1
                              </div>
                              <div className="step-content mt-10">
                                <h4 className="text-color-65589d obs-process-step-title">
                                  {other_settings.process.step1_title}
                                </h4>
                                {other_settings.process.step1_desc &&
                                other_settings.process.step1_desc !== "" ? (
                                  <>
                                    <p className="fw-300 font-color-716c80">
                                      {other_settings.process.step1_desc}
                                    </p>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : null}
                        {other_settings.process.step3_title &&
                        other_settings.process.step3_title !== "" ? (
                          <>
                            <div className="step mb-4 text-end d-flex flex-row-reverse">
                              <div className="process-step-circle process-step-circle-left">
                                3
                              </div>
                              <div className="step-content mt-10">
                                <h4 className="text-color-65589d obs-process-step-title">
                                  {other_settings.process.step3_title}
                                </h4>
                                {other_settings.process.step3_desc &&
                                other_settings.process.step3_desc !== "" ? (
                                  <>
                                    <p className="fw-300 font-color-716c80">
                                      {other_settings.process.step3_desc}
                                    </p>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : null}
                        {other_settings.process.step5_title &&
                        other_settings.process.step5_title !== "" ? (
                          <>
                            <div className="step text-end d-flex flex-row-reverse">
                              <div className="process-step-circle process-step-circle-left">
                                5
                              </div>
                              <div className="step-content mt-10">
                                <h4 className="text-color-65589d obs-process-step-title">
                                  {other_settings.process.step5_title}
                                </h4>
                                {other_settings.process.step5_desc &&
                                other_settings.process.step5_desc !== "" ? (
                                  <>
                                    <p className="fw-300 font-color-716c80">
                                      {other_settings.process.step5_desc}
                                    </p>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4 text-center p-0">
                      <img
                      src={
                              this.props.theme_data.avatars2 &&
                              this.props.theme_data.avatars2[0]
                                ? `${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.avatars2[0]["picture"]}`
                                : "../../../assets/theme/img/obs-theme/marketing-home-img-5-2.png"
                            }
                        alt="OnBoardSoft"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex flex-column align-items-start">
                        {other_settings.process.step2_title &&
                        other_settings.process.step2_title !== "" ? (
                          <>
                            <div className="step mb-4 text-start d-flex">
                              <div className="process-step-circle process-step-circle-right">
                                2
                              </div>
                              <div className="step-content mt-10">
                                <h4 className="text-color-65589d obs-process-step-title">
                                  {other_settings.process.step2_title}
                                </h4>
                                {other_settings.process.step2_desc &&
                                other_settings.process.step2_desc !== "" ? (
                                  <>
                                    <p className="fw-300 font-color-716c80">
                                      {other_settings.process.step2_desc}
                                    </p>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : null}
                        {other_settings.process.step4_title &&
                        other_settings.process.step4_title !== "" ? (
                          <>
                            <div className="step mb-4 text-start d-flex">
                              <div className="process-step-circle process-step-circle-right">
                                4
                              </div>
                              <div className="step-content mt-10">
                                <h4 className="text-color-65589d obs-process-step-title">
                                  {other_settings.process.step4_title}
                                </h4>
                                {other_settings.process.step4_desc &&
                                other_settings.process.step4_desc !== "" ? (
                                  <>
                                    <p className="fw-300 font-color-716c80">
                                      {other_settings.process.step4_desc}
                                    </p>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : null}
                        {other_settings.process.step6_title &&
                        other_settings.process.step6_title !== "" ? (
                          <>
                            <div className="step text-start d-flex">
                              <div className="process-step-circle process-step-circle-right">
                                6
                              </div>
                              <div className="step-content mt-10">
                                <h4 className="text-color-65589d obs-process-step-title">
                                  {other_settings.process.step6_title}
                                </h4>
                                {other_settings.process.step6_desc &&
                                other_settings.process.step6_desc !== "" ? (
                                  <>
                                    <p className="fw-300 font-color-716c80">
                                      {other_settings.process.step6_desc}
                                    </p>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container obs-process-steps-sm pt-5">
                  {other_settings.process.title &&
                  other_settings.process.title !== "" ? (
                    <>
                      <h2 className="text-center mb-5 mt-0 text-color-65589d fw-500 fs-3">
                        {other_settings.process.title}
                      </h2>
                    </>
                  ) : null}
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 text-center p-0 obs-process-steps-sm-img">
                      <img
                        src={
                              this.props.theme_data.avatars2 &&
                              this.props.theme_data.avatars2[0]
                                ? `${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.avatars2[0]["picture"]}`
                                : "../../../assets/theme/img/obs-theme/marketing-home-img-5-2.png"
                            }
                        alt="OnBoardSoft"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-6">
                      <div>
                        <div className="d-flex flex-column align-items-end">
                          {other_settings.process.step1_title &&
                          other_settings.process.step1_title !== "" ? (
                            <>
                              <div className="step mb-4 text-end d-flex flex-row-reverse">
                                <div className="process-step-circle process-step-circle-left">
                                  1
                                </div>
                                <div className="step-content mt-10">
                                  <h4 className="text-color-65589d obs-process-step-title">
                                    {other_settings.process.step1_title}
                                  </h4>
                                  {other_settings.process.step1_desc &&
                                  other_settings.process.step1_desc !== "" ? (
                                    <>
                                      <p className="fw-300 font-color-716c80">
                                        {other_settings.process.step1_desc}
                                      </p>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                          {other_settings.process.step2_title &&
                          other_settings.process.step2_title !== "" ? (
                            <>
                              <div className="step mb-4 text-end d-flex flex-row-reverse">
                                <div className="process-step-circle process-step-circle-left">
                                  2
                                </div>
                                <div className="step-content mt-10">
                                  <h4 className="text-color-65589d obs-process-step-title">
                                    {other_settings.process.step2_title}
                                  </h4>
                                  {other_settings.process.step2_desc &&
                                  other_settings.process.step2_desc !== "" ? (
                                    <>
                                      <p className="fw-300 font-color-716c80">
                                        {other_settings.process.step2_desc}
                                      </p>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                          {other_settings.process.step3_title &&
                          other_settings.process.step3_title !== "" ? (
                            <>
                              <div className="step mb-4 text-end d-flex flex-row-reverse">
                                <div className="process-step-circle process-step-circle-left">
                                  3
                                </div>
                                <div className="step-content mt-10">
                                  <h4 className="text-color-65589d obs-process-step-title">
                                    {other_settings.process.step3_title}
                                  </h4>
                                  {other_settings.process.step3_desc &&
                                  other_settings.process.step3_desc !== "" ? (
                                    <>
                                      <p className="fw-300 font-color-716c80">
                                        {other_settings.process.step3_desc}
                                      </p>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <div className="d-flex flex-column align-items-start">
                          {other_settings.process.step4_title &&
                          other_settings.process.step4_title !== "" ? (
                            <>
                              <div className="step mb-4 text-start d-flex">
                                <div className="process-step-circle process-step-circle-right">
                                  4
                                </div>
                                <div className="step-content mt-10">
                                  <h4 className="text-color-65589d obs-process-step-title">
                                    {other_settings.process.step4_title}
                                  </h4>
                                  {other_settings.process.step4_desc &&
                                  other_settings.process.step4_desc !== "" ? (
                                    <>
                                      <p className="fw-300 font-color-716c80">
                                        {other_settings.process.step4_desc}
                                      </p>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                          {other_settings.process.step5_title &&
                          other_settings.process.step5_title !== "" ? (
                            <>
                              <div className="step mb-4 text-start d-flex">
                                <div className="process-step-circle process-step-circle-right">
                                  5
                                </div>
                                <div className="step-content mt-10">
                                  <h4 className="text-color-65589d obs-process-step-title">
                                    {other_settings.process.step5_title}
                                  </h4>
                                  {other_settings.process.step5_desc &&
                                  other_settings.process.step5_desc !== "" ? (
                                    <>
                                      <p className="fw-300 font-color-716c80">
                                        {other_settings.process.step5_desc}
                                      </p>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                          {other_settings.process.step6_title &&
                          other_settings.process.step6_title !== "" ? (
                            <>
                              <div className="step text-start d-flex">
                                <div className="process-step-circle process-step-circle-right">
                                  6
                                </div>
                                <div className="step-content mt-10">
                                  <h4 className="text-color-65589d obs-process-step-title">
                                    {other_settings.process.step6_title}
                                  </h4>
                                  {other_settings.process.step6_desc &&
                                  other_settings.process.step6_desc !== "" ? (
                                    <>
                                      <p className="fw-300 font-color-716c80">
                                        {other_settings.process.step6_desc}
                                      </p>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {this.props.theme_data.sharelink_settings[0]["contact_email"] ? (
            <div
              id="contact"
              className="contact-style-one-area overflow-hidden default-padding"
            >
              <div className="container">
                <div className="row align-center">
                  <div className="contact-stye-one col-lg-12">
                    <div className="contact-form-style-one contact-form-style-one-customStyle row">
                      <div className="col-lg-5 col-sm-12 theme-3_contact-us-text_customStyle">
                        <div className="contact-style-one-info contact-style-one-info-customStyle contact-style-one-info-customStyle_contactUs">
                          <img
                            src="../../../assets/theme/img/obs-theme/contact_us_icon2.png"
                            alt="User Icon"
                            style={{ marginTop: "-5px", marginRight: "5px" }}
                          />
                          <h5 class="sub-heading sub-heading-secondary fontsize-sm-16 text-color-65589d">
                            {other_settings.contact.title &&
                            other_settings.contact.title !== ""
                              ? other_settings.contact.title
                              : "Contact Us"}
                          </h5>
                          <h2>
                            {other_settings.contact.heading &&
                            other_settings.contact.heading !== ""
                              ? other_settings.contact.heading
                              : "Get In Touch With Us"}
                          </h2>
                          <p>
                            {other_settings.contact.description &&
                            other_settings.contact.description !== ""
                              ? other_settings.contact.description
                              : "Have questions or need assistance? Reach out to our support team anytime. We're here to help ensure your communication needs are met with efficiency and ease"}
                          </p>
                          <ul class="feature-list-item feature-list-item-obs row">
                            {other_settings.contact.point1 &&
                            other_settings.contact.point1 !== "" ? (
                              <li
                                className="col-6 col-md-12 col-lg-12"
                                style={{ color: "black", fontWeight: "700" }}
                              >
                                {other_settings.contact.point1}
                              </li>
                            ) : null}
                            {other_settings.contact.point2 &&
                            other_settings.contact.point2 !== "" ? (
                              <li
                                className="col-6 col-md-12 col-lg-12"
                                style={{ color: "black", fontWeight: "700" }}
                              >
                                {other_settings.contact.point2}
                              </li>
                            ) : null}
                            {other_settings.contact.point3 &&
                            other_settings.contact.point3 !== "" ? (
                              <li
                                className="col-6 col-md-12 col-lg-12"
                                style={{ color: "black", fontWeight: "700" }}
                              >
                                {other_settings.contact.point3}
                              </li>
                            ) : null}
                            {other_settings.contact.point4 &&
                            other_settings.contact.point4 !== "" ? (
                              <li
                                className="col-6 col-md-12 col-lg-12"
                                style={{ color: "black", fontWeight: "700" }}
                              >
                                {other_settings.contact.point4}
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-7 col-sm-12 mt-sm-3">
                        <form
                          method="POST"
                          id="contact_form"
                          autoComplete="off"
                          className="contact-form contact-form"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div id="aler_error"></div>
                              <div id="aler_success"></div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group position-relative">
                                <label
                                  htmlFor="email"
                                  className="fw-400 text-black"
                                >
                                  {other_settings.contact.email_label &&
                                  other_settings.contact.email_label !== ""
                                    ? other_settings.contact.email_label
                                    : "Email"}
                                </label>
                                <div className="input-group">
                                  <input
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    defaultValue={this.state.email}
                                    onChange={this.handleChange}
                                    type="text"
                                    placeholder="Your Email"
                                  />
                                  <span className="input-icon">
                                    <img
                                      src="../../../assets/theme/img/obs-theme/email_icon2.png"
                                      alt="Email Icon"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label
                                  htmlFor="name"
                                  className="fw-400 text-black"
                                >
                                  {other_settings.contact.name_label &&
                                  other_settings.contact.name_label !== ""
                                    ? other_settings.contact.name_label
                                    : "Name"}
                                </label>
                                <div className="input-group">
                                  <input
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    defaultValue={this.state.name}
                                    onChange={this.handleChange}
                                    type="text"
                                    placeholder={
                                      other_settings.contact.name_label &&
                                      other_settings.contact.name_label !== ""
                                        ? other_settings.contact.name_label
                                        : "Name"
                                    }
                                    maxLength={100}
                                  />
                                  <span className="input-icon">
                                    <img
                                      src="../../../assets/theme/img/obs-theme/contact_form_user2.png"
                                      alt="Email Icon"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label
                                  htmlFor="phone"
                                  className="fw-400 text-black"
                                >
                                  {other_settings.contact.phone_label &&
                                  other_settings.contact.phone_label !== ""
                                    ? other_settings.contact.phone_label
                                    : "Phone"}
                                </label>
                                <div className="input-group">
                                  <input
                                    id="phone"
                                    name="phone"
                                    className="form-control"
                                    defaultValue={this.state.phone}
                                    onChange={this.handleChange}
                                    type="text"
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9]/gi,
                                        ""
                                      );
                                    }}
                                    placeholder={
                                      other_settings.contact.phone_label &&
                                      other_settings.contact.phone_label !== ""
                                        ? other_settings.contact.phone_label
                                        : "Phone"
                                    }
                                    maxLength={12}
                                  />
                                  <span className="input-icon">
                                    <img
                                      src="../../../assets/theme/img/obs-theme/phone_icon2.png"
                                      alt="Email Icon"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label
                                  htmlFor="subject"
                                  className="fw-400 text-black"
                                >
                                  {other_settings.contact.subject_label &&
                                  other_settings.contact.subject_label !== ""
                                    ? other_settings.contact.subject_label
                                    : "Subject"}
                                </label>
                                <div className="input-group">
                                  <input
                                    id="subject"
                                    name="subject"
                                    className="form-control"
                                    defaultValue={this.state.subject}
                                    onChange={this.handleChange}
                                    type="text"
                                    placeholder={
                                      other_settings.contact.subject_label &&
                                      other_settings.contact.subject_label !==
                                        ""
                                        ? other_settings.contact.subject_label
                                        : "Subject"
                                    }
                                    maxLength={50}
                                  />
                                  <span className="input-icon">
                                    <img
                                      src="../../../assets/theme/img/obs-theme/contact_form_subject2.png"
                                      alt="Email Icon"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                className="form-group comments"
                                style={{ marginBottom: "17px" }}
                              >
                                <label
                                  htmlFor="message"
                                  className="fw-400 text-black"
                                >
                                  {other_settings.contact.message_label &&
                                  other_settings.contact.message_label !== ""
                                    ? other_settings.contact.message_label
                                    : "Message"}
                                </label>
                                <div className="input-group">
                                  <textarea
                                    id="message"
                                    name="message"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.message}
                                    placeholder={
                                      other_settings.contact.message_label &&
                                      other_settings.contact.message_label !==
                                        ""
                                        ? other_settings.contact.message_label
                                        : "Message"
                                    }
                                    maxLength={500}
                                  ></textarea>
                                  <span className="input-icon message">
                                    <img
                                      src="../../../assets/theme/img/obs-theme/message_icon2.png"
                                      alt="Email Icon"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              {this.state.showCaptcha && (
                                <ReCAPTCHA
                                  sitekey={this.site_key}
                                  onChange={this.handleCaptchaChange}
                                  style={{ padding: "0px 0px 15px" }}
                                />
                              )}
                              <button
                                id="send_message"
                                className="btn circle btn-theme secondary btn-md animation custom_btn-Quotation3 radius custom_btn-Quotation3_contact-us-form_customStyle background-65589d"
                                style={{ width: "100%" }}
                              >
                                <i className="fa fa-paper-plane"></i>{" "}
                                {other_settings.contact.btn_text &&
                                other_settings.contact.btn_text !== ""
                                  ? other_settings.contact.btn_text
                                  : "Send Message"}
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-12 alert-notification">
                            <div id="message" className="alert-msg">
                              {this.state.errorMessage !== "" ? (
                                <>
                                  <div style={{ clear: "both" }}></div>
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {this.state.errorMessage}
                                  </div>
                                </>
                              ) : null}
                              {this.state.successMessage !== "" ? (
                                <>
                                  <div style={{ clear: "both" }}></div>
                                  <div
                                    className="alert alert-success"
                                    role="alert"
                                  >
                                    {this.state.successMessage}
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <footer className="text-light" style={{ background: "#343351" }}>
          <div>
            <div style={{ borderBottom: "1px solid rgb(255 255 255 / 21%)" }}>
              <div className="container">
                <div className="f-items relative pt-30 pb-30 pt-xs-30 pb-xs-50 ml-xs-40">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 text-center text-lg-start mb-3 mb-md-0">
                      <div class="d-flex align-items-center ms-sm-3 ms-xs-3">
                        <div
                          style={{
                            backgroundColor: "var(--white)",
                            borderRadius: "50%",
                            width: "60px",
                            height: "60px",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src="../../../assets/theme/img/obs-theme/group_obs.png"
                            alt="User Icon"
                            className="footer-icon-img-margin-obs"
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                          />
                        </div>
                        <div className="ms-3">
                          <h6
                            className="mb-1 fw-normal"
                            style={{ fontSize: "16px" }}
                          >
                            {other_settings.footer.support_title &&
                            other_settings.footer.support_title !== "" ? (
                              <>{other_settings.footer.support_title}</>
                            ) : null}
                          </h6>
                          <p
                            className="mb-0 fw-normal"
                            style={{ fontSize: "16px" }}
                          >
                            {other_settings.footer.contact_text &&
                            other_settings.footer.contact_text !== "" ? (
                              <>{other_settings.footer.contact_text} </>
                            ) : null}
                            {this.props.theme_data.sharelink_settings[0][
                            "contact_phone"
                          ] &&
                          this.props.theme_data.sharelink_settings[0][
                            "contact_phone"
                          ] !== "0" ? (
                            this.formatPhoneNumber(this.props.theme_data.sharelink_settings[0][
                              "contact_phone"
                            ])
                          ) : null}
                            
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-0 d-none d-lg-block text-center">
                      <div
                        style={{
                          borderLeft: "1px solid rgb(255 255 255 / 21%)",
                          height: "70px",
                          margin: "auto",
                        }}
                      ></div>
                    </div>
                    <div className="col-lg-5 col-md-6 text-center text-lg-start">
                      <div className="d-flex align-items-center justify-content-lg-end">
                        <div
                          style={{
                            backgroundColor: "var(--white)",
                            borderRadius: "50%",
                            width: "60px",
                            maxWidth: "60px",
                            minWidth: "60px",
                            height: "60px",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src="../../../assets/theme/img/obs-theme/019-flight_obs.png"
                            alt="User Icon"
                            className="footer-icon-img-margin-obs"
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                          />
                        </div>
                        <div className="ms-3">
                          <h6
                            className="mb-1 fw-normal"
                            style={{ fontSize: "16px", textAlign: "left" }}
                          >
                            {other_settings.footer.link_text &&
                            other_settings.footer.link_text !== "" ? (
                              <> {other_settings.footer.link_text} </>
                            ) : null}
                            <a
                              href={`${process.env.REACT_APP_AGENT_PORTAL_URL}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}`}
                              target="_blank"
                              className="text-left"
                            >
                              {
                                this.props.theme_data.reseller_detail[0]
                                  .reseller_comp
                              }
                            </a>
                            ?
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom footer-bottom-customStyle">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 d-xs-none">
                    <p>
                      &copy;{" "}
                      <a href="#">
                        {this.props.theme_data.reseller_detail[0].reseller_comp}
                      </a>{" "}
                      {new Date().getFullYear()}
                      {other_settings.footer.copyright_text &&
                      other_settings.footer.copyright_text !== "" ? (
                        <>| {other_settings.footer.copyright_text}</>
                      ) : null}
                    </p>
                  </div>
                  <div className="col-lg-6 text-end">
                    <ul style={{ fontSize: "14px" }}>
                      {(this.props.theme_data.sharelink_settings[0]
                        .facebook_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .facebook_link !== "https://www.facebook.com/") ||
                      (this.props.theme_data.sharelink_settings[0]
                        .twitter_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .twitter_link !== "https://twitter.com/") ||
                      (this.props.theme_data.sharelink_settings[0]
                        .instagram_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .instagram_link !== "https://www.instagram.com/") ||
                      (this.props.theme_data.sharelink_settings[0]
                        .linkedin_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .linkedin_link !== "https://www.linkedin.com/") ||
                      (this.props.theme_data.sharelink_settings[0]
                        .youtube_link !== "" &&
                        this.props.theme_data.sharelink_settings[0]
                          .youtube_link !== "https://www.youtube.com/") ? (
                        <>
                          {this.props.theme_data.sharelink_settings[0]
                            .facebook_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .facebook_link !== "https://www.facebook.com/" ? (
                            <li>
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .facebook_link
                                }
                                target="_blank"
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            </li>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0]
                            .twitter_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .twitter_link !== "https://twitter.com/" ? (
                            <li>
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .twitter_link
                                }
                                target="_blank"
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fab fa-twitter"></i>
                              </a>
                            </li>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0]
                            .instagram_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .instagram_link !== "https://www.instagram.com/" ? (
                            <li>
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .instagram_link
                                }
                                target="_blank"
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fab fa-instagram"></i>
                              </a>
                            </li>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0]
                            .linkedin_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .linkedin_link !== "https://www.linkedin.com/" ? (
                            <li>
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .linkedin_link
                                }
                                target="_blank"
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </li>
                          ) : null}
                          {this.props.theme_data.sharelink_settings[0]
                            .youtube_link !== "" &&
                          this.props.theme_data.sharelink_settings[0]
                            .youtube_link !== "https://www.youtube.com/" ? (
                            <li>
                              <a
                                href={
                                  this.props.theme_data.sharelink_settings[0]
                                    .youtube_link
                                }
                                target="_blank"
                                style={{ marginLeft: "5px" }}
                              >
                                <i className="fab fa-youtube"></i>
                              </a>
                            </li>
                          ) : null}
                        </>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {this.props.theme_data.sharelink_services.map((services) => (
          <div key={`serv${services.id}`}>
            <div
              className="modal fade"
              id={`serviceModal${services.id}`}
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby={`serviceModalLabel${services.id}`}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered service_modal">
                <div className="modal-content">
                  <button
                    type="button"
                    className="btn-close customStyle_modalCloseBtn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="modal-body">
                    <div className="soft-overview customSoft-overview-theme6">
                      <div className="customOverlay-theme3">
                        <div className="services-details-area overflow-hidden pt-50 pb-50">
                          <div className="container">
                            <div className="services-details-items">
                              <div className="row">
                                <div className="order-lg-last px-50 px-md-15 px-xs-15">
                                  <div className="row">
                                    <div className="col-lg-7">
                                      <h2 className="d-inline-block">
                                        {services.service_title}
                                      </h2>
                                    </div>
                                    <div className="col-lg-5 order_btn_wrapper_div">
                                      <div className="order_btn_div">
                                        <a
                                          className="btn btn-theme secondary btn-md animation order_btn customStyle-modalBtn mb-10"
                                          href={`${
                                            services.domain_name &&
                                            services.domain_name !== ""
                                              ? `${services.domain_name}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`
                                              : `${process.env.REACT_APP_AGENT_PORTAL_URL}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`
                                          }`}
                                        >
                                          {services.nda_required === "Yes"
                                            ? other_settings.services_modal
                                                .btn_text &&
                                              other_settings.services_modal
                                                .btn_text !== ""
                                              ? other_settings.services_modal
                                                  .btn_text
                                              : "Sign Up"
                                            : other_settings.services_modal
                                                .btn_text &&
                                              other_settings.services_modal
                                                .btn_text !== ""
                                            ? other_settings.services_modal
                                                .btn_text
                                            : "Order Now"}
                                        </a>
                                        {services.nda_required !== "Yes" && (
                                          <h3 className="order_btn_price">
                                            ${services.rate.toFixed(2)}
                                          </h3>
                                        )}
                                        {services.nda_required === "Yes" && (
                                          <p
                                            className="order_btn_price"
                                            style={{ fontSize: "14px" }}
                                          >
                                            * Sign Up to view pricing
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <p
                                    className="mb-40"
                                    dangerouslySetInnerHTML={{
                                      __html: services.product_desc,
                                    }}
                                  />
                                  {services.marketing_content === "pdf" &&
                                  services.marketing_link &&
                                  services.marketing_link !== "" ? (
                                    <div className="services-sidebar">
                                      <div className="single-widget widget-brochure customWidgetBrochure-theme6">
                                        {/* <h4 className="widget-title customWidgetTitle-theme6">
                                          Files
                                        </h4> */}
                                        <ul>
                                          <li>
                                            <a
                                              className="customRedirectBtn-theme6"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.openPDFlink(
                                                  services.marketing_link
                                                )
                                              }
                                            >
                                              <i
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "24px",
                                                }}
                                                className="fas fa-file"
                                              ></i>
                                              <input
                                                type="hidden"
                                                name="download_rate_deck"
                                                className="downloadratedeck"
                                                value={services.rate_deck_file}
                                              />
                                              {other_settings.services_modal
                                                .file_btn_text &&
                                              other_settings.services_modal
                                                .file_btn_text !== ""
                                                ? other_settings.services_modal
                                                    .file_btn_text
                                                : "View file"}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ) : services.marketing_content === "link" &&
                                    services.marketing_link &&
                                    services.marketing_link !== "" ? (
                                    <div className="services-sidebar">
                                      <div className="single-widget widget-brochure customWidgetBrochure-theme6">
                                        <ul>
                                          <li>
                                            <a
                                              className="customRedirectBtn-theme6"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.openLink(
                                                  services.marketing_link
                                                )
                                              }
                                            >
                                              <i
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "20px",
                                                }}
                                                className="fa fa-external-link"
                                              ></i>
                                              <input
                                                type="hidden"
                                                name="download_rate_deck"
                                                className="downloadratedeck"
                                                value={services.rate_deck_file}
                                              />
                                              {other_settings.services_modal
                                                .link_btn_text &&
                                              other_settings.services_modal
                                                .link_btn_text !== ""
                                                ? other_settings.services_modal
                                                    .link_btn_text
                                                : "Open link"}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ) : null}
                                  {services.nda_required === "No" ? (
                                    <>
                                      {(services.misc_settings &&
                                        services.misc_settings.some(
                                          (setting) =>
                                            setting.misc_title !== null
                                        )) ||
                                      (services.other_service_settings.some(
                                        (setting) =>
                                          setting.generic_title !== null
                                      ) &&
                                        services.other_service_settings.length >
                                          1) ? (
                                        <div className="faq-style-one faq-style-one-customStyle dark mt-40">
                                          <h3 className="mb-30">
                                            {other_settings.services_modal
                                              .pricing_heading &&
                                            other_settings.services_modal
                                              .pricing_heading !== ""
                                              ? other_settings.services_modal
                                                  .pricing_heading
                                              : "Pricing Detail"}
                                          </h3>
                                          <div
                                            className="accordion"
                                            id="faqAccordion"
                                          >
                                            {services.service_type === "did" ||
                                            services.service_type === "tfn" ||
                                            services.service_type ===
                                              "teams" ? (
                                              <div className="">
                                                <h5
                                                  className=""
                                                  id={`heading-${services.id}-details`}
                                                >
                                                  {other_settings.services_modal
                                                    .service_type_did_tfn_teams_table_title &&
                                                  other_settings.services_modal
                                                    .service_type_did_tfn_teams_table_title !==
                                                    ""
                                                    ? other_settings
                                                        .services_modal
                                                        .service_type_did_tfn_teams_table_title
                                                    : "Rates"}
                                                </h5>
                                                <div
                                                  id={`collapse-${services.id}-details`}
                                                  className=""
                                                  aria-labelledby={`heading-${services.id}-details`}
                                                >
                                                  <div className="">
                                                    {services.domain_name &&
                                                    services.domain_name !==
                                                      "" ? (
                                                      <input
                                                        type="hidden"
                                                        name="click_route"
                                                        className="click_route"
                                                        value={`${services.domain_name}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`}
                                                      />
                                                    ) : (
                                                      <input
                                                        type="hidden"
                                                        name="click_route"
                                                        className="click_route"
                                                        value={`${process.env.REACT_APP_AGENT_PORTAL_URL}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`}
                                                      />
                                                    )}
                                                    <div className="table-responsive">
                                                      <table className="table">
                                                        <thead className="thead-bg-customStyle">
                                                          <tr>
                                                            <th>
                                                              {other_settings
                                                                .services_modal
                                                                .service_type_did_tfn_teams_th_type &&
                                                              other_settings
                                                                .services_modal
                                                                .service_type_did_tfn_teams_th_type !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_type
                                                                : "Type"}
                                                            </th>
                                                            <th>
                                                              {other_settings
                                                                .services_modal
                                                                .service_type_did_tfn_teams_th_value &&
                                                              other_settings
                                                                .services_modal
                                                                .service_type_did_tfn_teams_th_value !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_value
                                                                : "Value"}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className="tbody-no-top-border">
                                                          {services.service_type ===
                                                            "did" ||
                                                          services.service_type ===
                                                            "tfn" ||
                                                          services.service_type ===
                                                            "teams" ? (
                                                            <>
                                                              <tr>
                                                                <th>
                                                                  {other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_mrc &&
                                                                  other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_mrc !==
                                                                    ""
                                                                    ? other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_mrc
                                                                    : "MRC"}
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.mrc.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>
                                                                  {other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_nrc &&
                                                                  other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_nrc !==
                                                                    ""
                                                                    ? other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_nrc
                                                                    : "NRC"}
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.nrc.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              {services.porting_settings &&
                                                              services
                                                                .porting_settings
                                                                .length > 0 ? (
                                                                <>
                                                                  <tr>
                                                                    <th>
                                                                      {other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_portin &&
                                                                      other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_portin !==
                                                                        ""
                                                                        ? other_settings
                                                                            .services_modal
                                                                            .service_type_did_tfn_teams_th_portin
                                                                        : "Port-in Rate"}
                                                                    </th>
                                                                    <td>
                                                                      $
                                                                      {services.porting_settings[0].portin_sell_rate.toFixed(
                                                                        2
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <th>
                                                                      {other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_portout &&
                                                                      other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_portout !==
                                                                        ""
                                                                        ? other_settings
                                                                            .services_modal
                                                                            .service_type_did_tfn_teams_th_portout
                                                                        : "Port-out Rate"}
                                                                    </th>
                                                                    <td>
                                                                      $
                                                                      {services.porting_settings[0].portout_sell_rate.toFixed(
                                                                        2
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                </>
                                                              ) : null}
                                                            </>
                                                          ) : null}
                                                          {services.service_type ===
                                                          "sms" ? (
                                                            <>
                                                              <tr>
                                                                <th>
                                                                  {other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_smsin &&
                                                                  other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_smsin !==
                                                                    ""
                                                                    ? other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_smsin
                                                                    : "SMS In Rate"}
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.sms_in.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>
                                                                  {other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_smsout &&
                                                                  other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_smsout !==
                                                                    ""
                                                                    ? other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_smsout
                                                                    : "SMS Out Rate"}
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.sms_out.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          ) : null}
                                                          {services.service_type ===
                                                          "cnam" ? (
                                                            <>
                                                              <tr>
                                                                <th>
                                                                  {other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_cnam &&
                                                                  other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_cnam !==
                                                                    ""
                                                                    ? other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_cnam
                                                                    : "Update CNAM (MRC)"}
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.cnam_mrc.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>
                                                                  {other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_dip &&
                                                                  other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_dip !==
                                                                    ""
                                                                    ? other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_dip
                                                                    : "DIP Charges"}
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.cnam_dip.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>
                                                                  {other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_storage_dip &&
                                                                  other_settings
                                                                    .services_modal
                                                                    .service_type_did_tfn_teams_th_storage_dip !==
                                                                    ""
                                                                    ? other_settings
                                                                        .services_modal
                                                                        .service_type_did_tfn_teams_th_storage_dip
                                                                    : "Storage DIP Charges"}
                                                                </th>
                                                                <td>
                                                                  $
                                                                  {services.cnam_dip_local.toFixed(
                                                                    2
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          ) : null}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {services.misc_settings &&
                                            services.misc_settings.length > 0 &&
                                            services.misc_settings.some(
                                              (misc) =>
                                                Number(misc.misc_rate) > 0
                                            ) ? (
                                              <>
                                                <div className="">
                                                  <h5
                                                    className=""
                                                    id={`heading-${services.id}-misc`}
                                                  >
                                                    {other_settings
                                                      .services_modal
                                                      .misc_charges_table_title &&
                                                    other_settings
                                                      .services_modal
                                                      .misc_charges_table_title !==
                                                      ""
                                                      ? other_settings
                                                          .services_modal
                                                          .misc_charges_table_title
                                                      : "Miscellaneous Charges"}
                                                  </h5>
                                                  <div
                                                    id={`collapse-${services.id}-misc`}
                                                    className=""
                                                    aria-labelledby={`heading-${services.id}-misc`}
                                                  >
                                                    <div className="customServiceTableStyles">
                                                      <table className="table">
                                                        <thead className="thead-bg-customStyle">
                                                          <tr>
                                                            <th data-label="Title">
                                                              {other_settings
                                                                .services_modal
                                                                .misc_charges_th_title &&
                                                              other_settings
                                                                .services_modal
                                                                .misc_charges_th_title !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .misc_charges_th_title
                                                                : "Title"}
                                                            </th>
                                                            <th data-label="Type">
                                                              {other_settings
                                                                .services_modal
                                                                .misc_charges_th_type &&
                                                              other_settings
                                                                .services_modal
                                                                .misc_charges_th_type !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .misc_charges_th_type
                                                                : "Type"}
                                                            </th>
                                                            <th data-label="Rate">
                                                              {other_settings
                                                                .services_modal
                                                                .misc_charges_th_rate &&
                                                              other_settings
                                                                .services_modal
                                                                .misc_charges_th_rate !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .misc_charges_th_rate
                                                                : "Rate"}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className="tbody-no-top-border">
                                                          {services.misc_settings.map(
                                                            (misc, ii) => (
                                                              <tr
                                                                key={`mis${ii}`}
                                                              >
                                                                <th data-label="Title">
                                                                  {
                                                                    misc.misc_title
                                                                  }
                                                                </th>
                                                                <td data-label="Type">
                                                                  {misc.misc_type ===
                                                                  "one_time"
                                                                    ? "One Time"
                                                                    : null}
                                                                  {misc.misc_type ===
                                                                  "per_minute"
                                                                    ? "Per Minute"
                                                                    : null}
                                                                  {misc.misc_type ===
                                                                  "per_hour"
                                                                    ? "Per Hour"
                                                                    : null}
                                                                </td>
                                                                <td data-label="Rate">
                                                                  $
                                                                  {Number(
                                                                    misc.misc_rate
                                                                  ).toFixed(2)}
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                            {services.service_type &&
                                            services.service_type ===
                                              "generic" &&
                                            services.other_service_settings
                                              .length > 1 ? (
                                              <>
                                                <div className="">
                                                  <h5
                                                    className=""
                                                    id={`heading-${services.id}-other`}
                                                  >
                                                    {other_settings
                                                      .services_modal
                                                      .pricing_breakdown_table_title &&
                                                    other_settings
                                                      .services_modal
                                                      .pricing_breakdown_table_title !==
                                                      ""
                                                      ? other_settings
                                                          .services_modal
                                                          .pricing_breakdown_table_title
                                                      : "Pricing Breakdown"}
                                                  </h5>
                                                  <div
                                                    id={`collapse-${services.id}-other`}
                                                    className=""
                                                    aria-labelledby={`heading-${services.id}-other`}
                                                  >
                                                    <div className="customServiceTableStyles">
                                                      <table className="table">
                                                        <thead className="thead-bg-customStyle">
                                                          <tr>
                                                            <th data-label="Title">
                                                              {other_settings
                                                                .services_modal
                                                                .pricing_breakdown_th_title &&
                                                              other_settings
                                                                .services_modal
                                                                .pricing_breakdown_th_title !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .pricing_breakdown_th_title
                                                                : "Title"}
                                                            </th>
                                                            <th data-label="Charge Type">
                                                              {other_settings
                                                                .services_modal
                                                                .pricing_breakdown_th_charge_type &&
                                                              other_settings
                                                                .services_modal
                                                                .pricing_breakdown_th_charge_type !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .pricing_breakdown_th_charge_type
                                                                : "Charge Type"}
                                                            </th>
                                                            <th data-label="Rate Type">
                                                              {other_settings
                                                                .services_modal
                                                                .pricing_breakdown_th_rate_type &&
                                                              other_settings
                                                                .services_modal
                                                                .pricing_breakdown_th_rate_type !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .pricing_breakdown_th_rate_type
                                                                : "Rate Type"}
                                                            </th>
                                                            <th data-label="Rate">
                                                              {other_settings
                                                                .services_modal
                                                                .pricing_breakdown_th_rate &&
                                                              other_settings
                                                                .services_modal
                                                                .pricing_breakdown_th_rate !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .pricing_breakdown_th_rate
                                                                : "Rate"}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className="tbody-no-top-border">
                                                          {services.other_service_settings.map(
                                                            (misc, ids) => (
                                                              <tr
                                                                key={`mis${ids}`}
                                                              >
                                                                <th data-label="Title">
                                                                  {
                                                                    misc.generic_title
                                                                  }
                                                                </th>
                                                                <td data-label="Charge Type">
                                                                  {misc.pay_type ===
                                                                  "recurring"
                                                                    ? "Recurring"
                                                                    : ""}

                                                                  {misc.pay_type ===
                                                                  "one_time"
                                                                    ? "One Time"
                                                                    : ""}
                                                                </td>
                                                                <td data-label="Rate Type">
                                                                  {misc.pay_type ===
                                                                  "recurring" ? (
                                                                    <>
                                                                      {misc.generic_type ===
                                                                      "per_minute"
                                                                        ? "Per Minute"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_hour"
                                                                        ? "Per Hour"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_second"
                                                                        ? "Per Second"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_day"
                                                                        ? "Per Day"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_month"
                                                                        ? "Per Month"
                                                                        : ""}
                                                                      {misc.generic_type ===
                                                                      "per_year"
                                                                        ? "Per Year"
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                </td>
                                                                <td data-label="Rate">
                                                                  $
                                                                  {misc.generic_rate &&
                                                                  misc.generic_rate >
                                                                    0
                                                                    ? misc.generic_rate.toFixed(
                                                                        2
                                                                      )
                                                                    : "0.00"}
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                            {!services.cancellation_fee ===
                                            0 ? (
                                              <div className="">
                                                <h5
                                                  className=""
                                                  id={`heading-${services.id}-details`}
                                                >
                                                  {other_settings.services_modal
                                                    .cancellation_fee_table_title &&
                                                  other_settings.services_modal
                                                    .cancellation_fee_table_title !==
                                                    ""
                                                    ? other_settings
                                                        .services_modal
                                                        .cancellation_fee_table_title
                                                    : "Cancellation Fee"}
                                                </h5>
                                                <div
                                                  id={`collapse-${services.id}-details`}
                                                  className=""
                                                  aria-labelledby={`heading-${services.id}-details`}
                                                >
                                                  <div className="">
                                                    <div className="table-responsive">
                                                      <table className="table">
                                                        <thead className="thead-bg-customStyle">
                                                          <tr>
                                                            <th>
                                                              {other_settings
                                                                .services_modal
                                                                .cancellation_fee_th_type &&
                                                              other_settings
                                                                .services_modal
                                                                .cancellation_fee_th_type !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .cancellation_fee_th_type
                                                                : "Type"}
                                                            </th>
                                                            <th>
                                                              {other_settings
                                                                .services_modal
                                                                .cancellation_fee_th_value &&
                                                              other_settings
                                                                .services_modal
                                                                .cancellation_fee_th_value !==
                                                                ""
                                                                ? other_settings
                                                                    .services_modal
                                                                    .cancellation_fee_th_value
                                                                : "Value"}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className="tbody-no-top-border">
                                                          <tr>
                                                            <th>N/A</th>
                                                            <td>
                                                              $
                                                              {services.cancellation_fee &&
                                                              services.cancellation_fee >
                                                                0
                                                                ? services.cancellation_fee.toFixed(
                                                                    2
                                                                  )
                                                                : "0.00"}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          className="modal fade zoom"
          tabIndex="-1"
          id="modalMarketingLink"
          style={{ overflow: "hidden" }}
        >
          <div
            className="modal-dialog modal-xl modal-dialog-top"
            role="document"
            style={{ maxWidth: "95%", height: "95vh" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div
                  className="modal-title"
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  <strong>
                    <em className="icon ni ni-list-round"></em> Marketing Link
                  </strong>
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.modalHideMarketingLink();
                  }}
                ></button>
              </div>

              <div className="modal-body">
                <br />
                <iframe
                  src={this.state.currentlink}
                  title="Marketing Link"
                  width="100%"
                  height={newHeight * 0.61}
                  style={{ border: "none" }}
                ></iframe>
                {console.log("my screen => ", window.screen.height)}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade zoom"
          tabIndex="-1"
          id="modalMarketingPDFLink"
          style={{ overflow: "hidden" }}
        >
          <div
            className="modal-dialog modal-xl modal-dialog-top"
            role="document"
            style={{ maxWidth: "95%", height: "95vh" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div
                  className="modal-title"
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  <strong>
                    <em className="icon ni ni-list-round"></em> File
                  </strong>
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.modalHideMarketingPDF();
                  }}
                ></button>
              </div>

              <div className="modal-body">
                <br />
                <embed
                  src={this.state.currentPdfLink}
                  type="application/pdf"
                  width="100%"
                  height={newHeight * 0.61}
                />
              </div>
            </div>
          </div>
        </div>
        <HelmetProvider>
          <Helmet>
            <script src="../../assets/theme/js/bootstrap.bundle.min.js"></script>
            <script src="../../assets/theme/js/jquery.appear.js"></script>
            <script src="../../assets/theme/js/jquery.easing.min.js"></script>
            <script src="../../assets/theme/js/jquery.magnific-popup.min.js"></script>
            <script src="../../assets/theme/js/modernizr.custom.13711.js"></script>
            {/*<script src="../../assets/theme/js/swiper-bundle.min.js"></script>*/}
            <script src="../../assets/theme/js/wow.min.js"></script>
            <script src="../../assets/theme/js/progress-bar.min.js"></script>
            <script src="../../assets/theme/js/circle-progress.js"></script>
            <script src="../../assets/theme/js/isotope.pkgd.min.js"></script>
            <script src="../../assets/theme/js/imagesloaded.pkgd.min.js"></script>
            <script src="../../assets/theme/js/jquery.nice-select.min.js"></script>
            <script src="../../assets/theme/js/count-to.js"></script>
            <script src="../../assets/theme/js/jquery.scrolla.min.js"></script>
            <script src="../../assets/theme/js/YTPlayer.min.js"></script>
            <script src="../../assets/theme/js/TweenMax.min.js"></script>
            <script src="../../assets/theme/js/rangeSlider.min.js"></script>
            <script src="../../assets/theme/js/jquery-ui.min.js"></script>
            <script src="../../assets/theme/js/validnavs.js"></script>
            <script src="../../assets/theme/js/main.js"></script>
          </Helmet>
        </HelmetProvider>
      </>
    );
  }
}

export default Theme9;
